<template>

  <section id="news" class="list-news">
    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          Conoce todas las noticias que están publicadas en Linkiwork. 
          Podrás <strong>revisar</strong>, <strong>editar</strong> o <strong>eliminar</strong> 
          alguna si lo requieres.
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link 
          to="/noticias/add" 
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Crear noticia
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-6 col-lg-7 input-search">
            <input 
                type="search" 
                class="form-control input-custom"
                placeholder="Buscar noticia" 
                v-model.trim="filter.search" 
                ref="searchInput"
                @keyup.enter="getNoticias()"
                @keyup.delete="checkEmptyInput()"/>
                <span class="input-search-icon">
                  <font-awesome-icon icon="search"/>
                </span>
          </div>
          <div class="col-6 col-lg-5">
            <v-select
              class="selvue-custom"
              :clearable="true"
              :options="data_categoria"
              v-model="filter.sel_categoria"
              placeholder="Categoría">
              <span slot="no-options"></span>
            </v-select>     
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
            Filtrar por periodo:
          </div>
          <div class="col-12 col-lg-9 col-xl-8">

            <div class="row">
              <div class="col-6">
                <InputDate 
                  :max="range.date_end"
                  placeHolder="Inicio"
                  @dateEmit="captureDateStart"
                />
              </div>
              <div class="col-6">
                <InputDate 
                  :min="range.date_start" 
                  placeHolder="Término"
                  @dateEmit="captureDateEnd"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Loading 
      v-if="loading"
      pixeles="150px"
    />

    <template v-if="!loading">
      <div
        class="row" 
        v-show="newsArr.length > 0 && no_results == ''"
      >
        <div 
          class="col-12 col-md-6 col-lg-4" 
          v-for="(n, i) in newsArr" :key="i">
          <article class="card card-item card-shadow border-round-10 border-0 mb-3">
            <figure class="card-item-figure">
                <div class="card-item-config">
                  <a 
                    href="javascript:" 
                    @click="(noticia_selected = n)"
                    data-bs-toggle="modal" 
                    data-bs-target="#news-detail"
                    class="card-item-config-btn"
                    content="Ver noticia" 
                    v-tippy="{ arrow: true }"
                  >
                    <font-awesome-icon icon="eye"/>
                  </a>
                  <a 
                    href="javascript:" 
                    @click="editNoticia(n)"
                    class="card-item-config-btn"
                    content="Editar noticia" 
                    v-tippy="{ arrow: true }"
                  >
                    <font-awesome-icon icon="pen"/>
                  </a>
                  <a 
                    href="javascript:" 
                    @click="openDeleteModal(n)"
                    class="card-item-config-btn"
                    content="Eliminar noticia" 
                    v-tippy="{ arrow: true }" 
                  >
                    <font-awesome-icon icon="trash-alt"/>
                  </a>
                  <a 
                    href="javascript:" 
                    @click="changeStateNoticia(n)"
                    class="card-item-config-btn"
                    :content="(n.id_estado_fk == 1 ? 'Deshabilitar' : 'Habilitar') + ' noticia' "
                    v-tippy="{ arrow: true }" 
                  >
                    <font-awesome-icon :icon="n.id_estado_fk == 1 ? 'ban' : 'check-circle'"/>
                  </a>
                </div>
                <div class="card-item-future" v-if="n.id_estado_fk == '3'">
                  <h5 class="mb-0 text-center text-white">
                    <strong>Noticia deshabilitada</strong>
                  </h5>
                </div>
                <div class="card-item-future" v-if="futureNew(n.fecha_noticia)">
                  <p class="mb-0 text-center text-white">
                    Esta noticia se publicará <br>
                    el <strong>{{ dateNewFormat(n.fecha_noticia, 1) }}</strong><br>
                    a las <strong>{{ hourFormat(n.fecha_noticia) }}</strong>
                </p>
                </div>
                <span class="card-item-date">
                  {{ dateNewFormat(n.fecha_noticia, 1) }}
                </span>
                <img 
                  :src="n.url_imagen_principal" 
                  class="card-item-figure-img"
                >
            </figure>
            <div class="card-body card-item-excerpt">
              <h5 class="card-item-title-2 font-main color-gray mb-0">
                {{ n.titulo_noticia }}
              </h5>
            </div>
          </article>
        </div>
      </div>
  
      <SinResultados
        msg="Aceptar" 
        custom-click 
        @click="nuevaBusqueda"  
        v-if="no_results !== '' "/>

    </template>

    <div class="users-list__pagination" v-if="this.pagination.page_count > 1">
      <paginate v-model="pagination.actual_page" :page-count="pagination.page_count" :page-range="pagination.page_range"
        :click-handler="pagination.click_handler" :prev-text="pagination.prev_text" :next-text="pagination.next_text"
        :container-class="pagination.container_class" :page-class="pagination.page_class">
      </paginate>
    </div>

    <!-- Modal -->
    <div 
      class="modal modal-custom fade noticia-selected" 
      tabindex="-1" 
      aria-hidden="true"
      id="news-detail">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">
              <font-awesome-icon icon="newspaper" class="color-main"/>
              <span class="date-a">
                {{ dateFormatDiaMes(noticia_selected.fecha_noticia) }}
            </span>
            </h1>
            <button 
              type="button" 
              class="modal-custom-close"
              data-bs-dismiss="modal" 
              aria-label="Close">
              <font-awesome-icon icon="xmark"/>
            </button>
          </div>
          <div class="modal-body">

            <div class="row">
              <div class="col-12">
                <figure>
                  <img :src="noticia_selected.url_imagen_principal" class="modal-custom-img border" />
                </figure>
                <!-- TITLE -->
                <h4 class="modal-custom-title">
                  {{ noticia_selected.titulo_noticia }}
                </h4>
              </div>
            </div>

            <div class="row" v-for="(content, k) in noticia_selected.contenido" :key="k">
              <div class="col-12">

                <!--TEXT-->
                <div 
                  class="modal-custom-text" 
                  v-if="content.texto !== null && content.tipo_contenido[0].id_tipo_contenido == '1'" 
                  v-html="content.texto">
                </div>

                <!--IMAGE-->
                <div 
                  class="image" 
                  v-if="content.url_imagen !== null && content.tipo_contenido[0].id_tipo_contenido == '2'"
                >
                  <a 
                    class="modal-custom-link-img"
                    v-if="content.url_externo_intranet != ''"
                    :href="content.url_externo_intranet"
                    :id="`img[${k}]`" 
                    target="_blank" 
                  >
                    <figure class="text-center">
                      <img 
                        class="figure-img img-fluid rounded-4"
                        :src="content.url_imagen"
                      />
                    </figure>
                  </a>
                  <figure v-if="content.url_externo_intranet == ''" class="text-center">
                    <img 
                      class="figure-img img-fluid rounded-4"
                      :src="content.url_imagen" 
                    />
                  </figure>
                </div>

                <!--VIDEO-->
                <div 
                  class="ratio ratio-16x9 mb-3"
                  v-if="content.url_video !== null && content.tipo_contenido[0].id_tipo_contenido == '3'"
                  >
                  <video 
                    :src="content.url_video" 
                    controls 
                    class="border"
                    alt="Video">
                  </video>
                </div>

                <!--DOCUMENT-->
                <div 
                  class="alert alert-secondary d-flex align-items-center justify-content-between p-2"
                  v-if="content.url_documento !== null && content.tipo_contenido[0].id_tipo_contenido == '4'"
                >
                  <span>
                    <font-awesome-icon icon="file" class="color-green"/>
                    Documento
                  </span>
                  <a 
                    :href="content.url_documento" 
                    target="_blank"
                    class="btn btn-secondary btn-sm"
                  >
                    <font-awesome-icon icon="download"/>
                      {{ content.texto }}
                  </a>
                </div>

                <!--USER-->
                <div 
                  class="user" 
                  v-if="content.usuario.length > 0 && content.tipo_contenido[0].id_tipo_contenido == '5'"
                >
                  <div 
                    class="detail" 
                    v-for="(user, y) in content.usuario" 
                    :key="y"
                  >
                    <img 
                      class="autor-img" 
                      :src="user.imagen_perfil ? user.imagen_perfil : user_default_img" 
                      height="20"
                    />
                    <div class="autor-container">
                      <div class="autor-title">Autor</div>
                      <div class="autor">
                        {{ user.primer_nombre + " " + user.apellido_paterno }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>    
          </div>
          <div class="modal-footer">
            <button 
              type="button" 
              class="btn btn-custom btn-custom-color-blue border-round-50" 
              @click="editNoticia(noticia_selected)"
              data-bs-dismiss="modal"
            >
              <font-awesome-icon icon="pen" class="pe-2"/>
              Editar
            </button>
            <button
              type="button" 
              class="btn btn-custom btn-custom-color-red border-round-50" 
              @click="openDeleteModal(noticia_selected)"
            >
              <font-awesome-icon icon="trash-alt" class="pe-2"/>
              Eliminar
            </button>              
          </div>
        </div>
      </div>
    </div>

    <!-- Modal pregunta -->
    <Question 
      v-if="open_question_modal" 
      :msg="question_modal_msg" 
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />
    <!--  Modal que muestra el estado de la accion (exito o error) -->
  </section>

</template>

<script>
  import { mapState, mapActions } from "vuex";
  import moment from "moment";
  import Question from "../Modales/Question.vue";

  //FF
  import InputDate from "@/components/forms/InputDate.vue"
  import Loading from "@/components/Loading.vue";
  import SinResultados from "@/components/SinResultados.vue";

  export default {
    components: { 
      Question,

      //FF
      InputDate,
      Loading,
      SinResultados
    },
    data() {
      return {
        user_default_img: require("@/assets/img/user_default_img.svg"),
        pagination: {
          actual_page: 1,
          page_count: 0,
          page_range: 3,
          click_handler: this.changePage,
          prev_text:
            '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
          next_text:
            '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
          container_class: "users-list__pagination-container",
          page_class: "pagination-item",
        },
        meses: [
          "en",
          "febr",
          "mar",
          "abr",
          "my",
          "jun",
          "jul",
          "agt",
          "set",
          "oct",
          "nov",
          "dic",
        ],
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        id_usuario: this.$ls.get("user").id_usuario,
        noticia_selected: {},
        no_results: "",
        question_modal_msg: "¿Deseas eliminiar esta noticia?",
        open_question_modal: false,
        noticias: [],

        page_limit: "30",

        //FF
        loading: true,
        filter: { 
          search: '',
          sel_categoria: null,
        },
        data_categoria: [],
        range: {
          date_start: null,
          date_end: null,
          mask_start: null,
          mask_end: null
        }
      };
    },
    watch: {
      //FF
      "filter.search"(value) {
        if(value === '') {
          this.getNoticias();
        }
      },
      "filter.sel_categoria"() {
          this.getNoticias()
      },
      "range.date_start"() {
          this.getNoticias();
      },
      "range.date_end"() {
          this.getNoticias();    
      },
    },
    async created() {
      //FF
      await this.getCategoria();
      await this.getNoticias();
    },
    computed: {
      ...mapState("newsModule", [
        "news_list",
        "news_list_add",
        "sidebar_open",
        "news_deleted",
        "paginationArr",
        "newsArr",
        "categories"
      ]),
    },

    methods: {
      ...mapActions("newsModule", [
        "deleteNews", 
        "getNewsPagination",
        "getNewsPaginated",
        "getNewsCategories",
        "changeNewsState"
      ]),
      nuevaBusqueda() {
        this.filter.search = '';
        this.filter.sel_categoria = null;
        
        this.$refs.searchInput.focus();
        this.getNoticias(); 
      },
      //Formatear fecha
      dateFormatDiaMes(date) {
      let day = new Date(date);
      let month = day.getMonth();
      return `${day.getDate()} ${this.meses[month]}`;
      },
      async getNewsPages() { 
        let data_ = {
          nombre_noticia: this.filter.search.toLowerCase(),
          id_empresa: this.id_empresa,
          id_categoria: this.filter.sel_categoria !== null ? this.filter.sel_categoria.id : '0',
          fecha_inicio: this.range.date_start !== null ? this.range.mask_start : '',
          fecha_fin: this.range.date_end !== null ? this.range.mask_end : moment().endOf('month').format('YYYY-MM-DD'),
          limite: this.page_limit
        }
        await this.getNewsPagination(data_);
        this.pagination.page_count = this.paginationArr.length;
      },

      async getNewsByPage(page){
        this.loading = true;
        let data_ = {
          id_usuario:  this.$ls.get("user").id_usuario,
          id_empresa: this.id_empresa,
          id_noticia: this.paginationArr[(page - 1)].noticias,
        }
        await this.getNewsPaginated(data_)
        this.loading = false;
      },

      async getNoticias() {
        this.loading = true;
        await this.getNewsPages();
        if(this.paginationArr.length > 0) {
          this.pagination.actual_page = 1;
          await this.getNewsByPage(this.pagination.actual_page);
          this.no_results = "";
          this.loading = false;
        }
        else {
          this.no_results = this.$noResults;
          this.loading = false;
        }
      },

      async changePage(page){
        this.loading = true;
        this.pagination.actual_page = page;
        await this.getNewsByPage(this.pagination.actual_page);
        this.loading = false;
      },

      async changeStateNoticia(noticia) {
        //this.loading = true;
        const payload = {
          id_empresa: this.id_empresa,
          id_usuario: this.id_usuario,
          id_noticia: noticia.id_noticia,
          id_estado: noticia.id_estado_fk == "1" ? "3" : "1",
          alerta_push: noticia.alerta_push ?? "0"
        };
        //console.log("params: ", payload);
        const resChangeState = await this.changeNewsState(payload);
        if (resChangeState) {
          this.toastStatusChangeSuccess();
        } else {
          this.toastStatusChangeError();
        }
        //this.loading = false;
      },
      // funcion para abrir modal para preguntar si se elimina la noticia
      openDeleteModal(noticia) {
        this.noticia_selected = noticia;
        this.open_question_modal = true;
      },
      // Funcion para "cancelar" la pregunta del Modal Question
      cancelQuestion() {
        this.open_question_modal = false;
      },
      // Funcion para "aceptar" la pregunta del modal Question (eliminar usuario)
      async acceptQuestion() {
        this.open_question_modal = false;
        let data = {
          id_empresa_fk: this.id_empresa,
          id_noticia: this.noticia_selected.id_noticia,
          id_eliminador: this.$ls.get("user").id_usuario,
        }
        await this.deleteNews(data).then(res =>{
          if(res != null) this.$toast.open({
            message: 'Noticia eliminada exitosamente.',
            type: 'success',
            duration: 6000,
            position: 'top-right'
          });
          else this.$toast.open({
            message: 'Ocurrió un error al eliminar la noticia, intentalo nuevamente.',
            type: 'error',
            duration: 0,
            position: 'top-right'
          });
        });
        this.false = true;
        //this.loading = true;
        
      },
      // Funcion que abre componente "Add" para editar una noticia
      editNoticia(news) {
        this.$router.push({
            name: "news-edit",
            params: { id: news.id_noticia },
          })
          .catch(() => {
            return;
          });
      },
      //Fecha futura
      futureNew(date) {
        let day = new Date(date);
        let toDay = new Date();
        return day > toDay;
      },
      //Formatear fecha
      dateNewFormat(date, type) {
        let day = new Date(date);
        let month = day.getMonth();
        if (type == 1) {
          return `${day.getDate()}/${
            month + 1 < 10 ? `0${month + 1}` : month + 1
          }/${day.getFullYear()}`;
        }
        if (type == 2) {
          return `${day.getDate()};${this.meses[month]}`;
        }
      },
      //Formatear Hora
      hourFormat(date) {
        let day = new Date(date);
        let hours = day.getHours();
        let min = day.getMinutes();
        return `${hours < 10 ? `0${hours}` : hours}:${
          min < 10 ? `0${min}` : min
        }`;
      },
      checkEmptyInput() {
        if(this.filter.search == '') this.getNoticias();
      },

      //FF
      async getCategoria() {
        await this.getNewsCategories({ id_empresa: this.$ls.get("user").empresa[0].id_empresa }).then(res => {
            let data = [];
            res.forEach((element) => data.push({
              id: element.id_categoria,
              type: element.__typename,
              label: element.nombre_categoria
            }));
            this.data_categoria = data;
          })
      },
      captureDateStart(date) {
        this.range.date_start = date;
        this.range.mask_start = moment(date).format('YYYY-MM-DD');
      },
      captureDateEnd(date) {
        this.range.date_end = date;
        this.range.mask_end = moment(date).format('YYYY-MM-DD');
      },
    },
  };
</script>
